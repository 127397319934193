import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'
import Dummy3 from '../assets/img/dummy3.jpg'
import Data from '../../../../data/inpage/ntv/data-video.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'


function ArtikelTeaserBlockStars({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
   

     <swiper-slide style={{display:'flex', justifyContent: 'center',  position:'relative'}}>

     <div className='gala-article_teaser-block-stars'>
      
      <div className='gala-article_teaser-block-img-container-stars'>
      <img className='gala-article_teaser-block-img-stars' src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
      
   
      </div>

      <div className='gala-article_teaser-block-text-container-stars'>
      <p className='gala-article_teaser-block-desk-head-stars'>
      {category}
      </p>

      <p className='gala-article_teaser-block-desk-text-stars'> {title}</p>

     
      <p className='gala-article_teaser-block-text_author-stars'>{author}</p>
      </div>

      
  </div>

  </swiper-slide>











 
    

   </>
 
  );


  }






export default ArtikelTeaserBlockStars;
