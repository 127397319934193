import React,{useEffect, useState, Component, useRef} from 'react';
import Wolke from '../assets/logos/wolke.png'
import Data from '../../../../data/inpage/ntv/data_day.json'
import './styles.scss'


function PanelNews() {

  /*
    const hour=String(time.getHours()).padStart(2, '0')
    const min=String(time.getMinutes()).padStart(2, '0')
  */


  function getime() {
   
    const time = new Date();
    

    const currentHours=String(time.getHours()).padStart(2, '0')
    const currentMinutes=String(time.getMinutes()).padStart(2, '0')
    
    const randomHours = Math.floor(Math.random() * (parseInt(currentHours) + 1));
    
  
    const randomMinutes = randomHours === currentHours
      ? Math.floor(Math.random() * (parseInt(currentMinutes) + 1))
      : Math.floor(Math.random() * 60);
    


 
  
    
   return `${String(randomHours).padStart(2, '0')}:${String(randomMinutes).padStart(2, '0')}`
  }
   
  
 
  
   function getDate(){
  const date=new Date()
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth()+1).padStart(2, '0')
  const year = date.getFullYear()
  
  return `${day}.${month}.${year}`
 }
  

 const test=useRef(null)


  useEffect(()=>{

    const swiperEl = document.querySelector('swiper-container');
    const paginationWidth=swiperEl.swiper.pagination.el
    const btnNext=swiperEl.swiper.navigation.nextEl
    const btnPrev=swiperEl.swiper.navigation.prevEl
    
    console.log(paginationWidth.clientWidth)

btnNext.style.right=`calc(50% - ${paginationWidth.clientWidth}px / 2 - 40px)`
btnPrev.style.left=`calc(50% - ${paginationWidth.clientWidth}px / 2 - 40px)`


getime()
  
},[])


 



  return (
   <div className='ntv-panel-news'>

    <div className='ntv-panel-news-headText_container'>
      <p>das neueste</p>
    </div>

    

<swiper-container id="ntv-hightlight-swiper" slides-per-view="1" autoplay-delay="5000" navigation="true"  pagination="true" pagination-clickable="true" css-mode="true">

   <SetPanelNews/>

</swiper-container>
  
   </div>
 
  );



  function SetPanelNews(){

    return Data.rss.channel.item.map((item,index)=>{
      const [beforeColon, afterColon] = item.title.split(':');
      return(
      <swiper-slide>

      <div className='ntv-hightlight-swiper-item'>
      <SetTimeBlock/>
      <div className='ntv-hightlight-swiper-item-news_container'>
      <p className='ntv-hightlight-swiper-item-header'>{beforeColon}</p>
      <p className='ntv-hightlight-swiper-item-text'>{afterColon}</p>
      </div>
      </div>
      
      </swiper-slide>
      )

    })
  }

  function SetTimeBlock(){
    
    return(
    <div className='ntv-panel-news-date_container'>
      <div className='ntv-panel-news-date ntv-panel-news-dateBlock'>{getDate()}</div>
      <div className='ntv-panel-news-date'>{getime()}</div>
    </div>
    )
  }


  }




export default PanelNews;
