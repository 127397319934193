import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'

//import Data from '../../../../data/inpage/ntv/data-video.json'
import Data from '../../../../data/inpage/ntv/data-video2.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'


function ArtikelBox() {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
        <SetBox/>

   </>
 
  );


  }


  function SetBox(){

    //return Data.rss.channel.item.map((item,index)=>{
    return Data.rss.channel.item.map((item,index)=>{
      const [beforeColon, afterColon] = item.title.split(':');
        
        return(
<div className='ntv-article-box-videos_container' key={index}>
            <div className='ntv-article-box-videos-img-container'>
            <img src={item.enclosure._url} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
            <div className='ntv-article-side-videos-video_icon'>
                <div className='ntv-article-side-videos-video_icon-play'></div>
            </div>
            </div>
            
            <div className='ntv-article-text-container-side'>
                <p className='ntv-article-side-theme'> {item.category}</p>
                <p className='ntv-article-side-desk-head'>
                {beforeColon}
                </p>

                <p className='ntv-article-side-desk-text'> {afterColon}</p>

                 
                </div>

        </div>
        )
    })
   

   }





export default ArtikelBox;
