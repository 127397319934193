import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Pic1 from '../assets/img/1.jpg'
import Pic2 from '../assets/img/2.jpg'
import Pic3 from '../assets/img/3.jpg'
import './styles.scss'


function ArtikelTeaserFooter() {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
      <div className='gala-container_teaser-footer-header'>
                  <p className="gala-container_teaser-footer-header-text">gala entdecken</p>
                  <div className='gala-stars_container-header_svg-container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#B2804B" d="M20.67 22.67q.42-2.265 1.68-4.05c.84-1.2 1.8-2.2 2.89-2.96H0v-1.99h25.24a12 12 0 0 1-2.89-2.93c-.84-1.2-1.4-2.56-1.68-4.07h2.57c.27 1 .59 1.95.97 2.83.4.87.89 1.62 1.46 2.27a6.8 6.8 0 0 0 2.08 1.54c.8.38 1.75.57 2.83.57h.09v1.57h-.09a6.5 6.5 0 0 0-2.83.58c-.8.38-1.5.9-2.08 1.53a9 9 0 0 0-1.46 2.3c-.38.88-.7 1.82-.97 2.8h-2.57z"/></svg>
                </div>
                  </div>
     <div className="gala-article-container">
    
      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={Pic1}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <p className='gala-article-main-desk-head'>
                gala
                </p>

                <p className='gala-article-main-desk-text'> Inhaltsverzeichnis</p>
  
                
                </div>
        
                
            </div>



      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={Pic2}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <p className='gala-article-main-desk-head'>
                Web, App, Pushnachrichten + Feeds
                </p>

                <p className='gala-article-main-desk-text'> So erhalten Sie Ihre GALA-News immer und überall</p>
  
                
                </div>
        
                
            </div>



      <div className='gala-article-container-main'>
      
                <div className='gala-article-img-container-main'>
                <img className='gala-article-img' src={Pic3}/>
                
             
                </div>
        
                <div className='gala-article-text-container-main'>
                <p className='gala-article-main-desk-head'>
                Alles über Ihre Lieblingsstars
                </p>

                <p className='gala-article-main-desk-text'> Daily Newsflash</p>
  
                
                </div>
        
                
            </div>
          
            
            </div>
   </>
 
  );


  }






export default ArtikelTeaserFooter;
