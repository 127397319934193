import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Dummy from '../assets/img/mainDummy.jpg'
import Data from '../../../../data/inpage/gala/data.json'
import './styles.scss'
import NoImg from '../../../../Images/noimg.jpg'

function ArtikelHighLight({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    
    <div className='gala-hightlight_article'>
    
    <div className='gala-hightlight_article1_container'>
    <SetHighLight/>

      </div>

    <div className='gala-hightlight_article2_container'>
    
      <SetHighLight2/>

      </div>

    <div className='gala-hightlight_article3_container'>

    <SetHighLight3/>

      </div>

      </div>
  );

  function SetHighLight(){

    return Data.channel.item.slice(0,1).map((item,index)=>{
 
        return(
          <>
          <div className='gala-hightlight_article1-text_container'>
          <p className='gala-hightlight_article1-text_title'>{item.category[0]}</p>
          <p className='gala-hightlight_article1-text_desc'>{item.title}</p>
          </div>
          <div className='gala-hightlight_article1-img_container'><img src={item.enclosure['@url']} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/></div>
          </>
        )
      
    })
   
  }

  function SetHighLight2(){

    return Data.channel.item.slice(1,3).map((item,index)=>{
 
        return(
          <div className='gala-hightlight_article2'>
          <div className='gala-hightlight_article2-img_container'><img src={item.enclosure['@url']} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/></div>
          <div className='gala-hightlight_article2-text_container'>
          <p className='gala-hightlight_article2-text_title'>{item.category[0]}</p>
          <p className='gala-hightlight_article2-text_desc'>{item.title}</p>
          </div>
          
          </div>
        )
      
    })
   
  }

  function SetHighLight3(){

    return Data.channel.item.slice(3,7).map((item,index)=>{
 
        return(
          <div className='gala-hightlight_article3'>
          <div className='gala-hightlight_article3-img_container'><img src={item.enclosure['@url']} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/></div>
          <div className='gala-hightlight_article3-text_container'>
          <p className='gala-hightlight_article3-text_title'>{item.category[0]}</p>
          <p className='gala-hightlight_article3-text_desc'>{item.title}</p>
          </div>
          
          </div>
        )
      
    })
   
  }


  }








export default ArtikelHighLight;
