import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Dummy from '../assets/img/mainDummy.jpg'
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import './styles.scss'
import NoImg from '../../../../Images/noimg.jpg'

function ArtikelHighLight2({img, title, rate}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    
        
        <div className='chef-hightlight_article2_container'>

        <div className='chef-hightlight_article2'>
        <div className='chef-hightlight_article2-img_container'><img src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/></div>
        <Box className="chef-rating_container" sx={{ '& > legend': { mt: 2 } }}>
        <Rating className='chef-rating-star' size="small" name="read-only" value={1} max={1} readOnly/>
        <div className='chef-rating-value'>{rate}</div>
        </Box>
      <div className='chef-hightlight_article2-text_container'>

      <p className='chef-hightlight_article2-text_desc'>{title}</p>
      </div>
      
      </div> 
      </div>

   
 
  );


  }








export default ArtikelHighLight2;
