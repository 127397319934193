import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'
import Dummy3 from '../assets/img/dummy3.jpg'
import Data from '../../../../data/inpage/ntv/data-video.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'


function ArtikelTeaserBlock({img, category, title, text, author}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
   

     

     <div className='gala-article_teaser-block'>
      
      <div className='gala-article_teaser-block-img-container'>
      <img className='gala-article_teaser-block-img' src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
      
   
      </div>

      <div className='gala-article_teaser-block-text-container'>
      <p className='gala-article_teaser-block-desk-head'>
      {category}
      </p>

      <p className='gala-article_teaser-block-desk-text'> {title}</p>

     
      <p className='gala-article_teaser-block-text_author'>{author}</p>
      </div>

      
  </div>













 
    

   </>
 
  );


  }






export default ArtikelTeaserBlock;
