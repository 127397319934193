import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import Data from '../../../../data/inpage/gala/data.json'
import './styles.scss'


function NewsTicker() {


   


  function getime(index) {
   
   const time = new Date();

// Определяем диапазон часов (от текущего - 2 до текущего)
const startHour = time.getHours() - 2;
const endHour = time.getHours();

// Разные такты минут
const minuteDifferences = [3, 7, 12, 15, 20, 23, 28, 30, 35, 41];

const timeArray = [];
let currentHour = startHour;
let currentMinute = time.getMinutes();

// Генерируем 10 временных значений
for (let i = 0; i < 10; i++) {
    currentMinute += minuteDifferences[i];

    if (currentMinute >= 60) {
        currentMinute -= 60;
        currentHour++;
    }

    if (currentHour > endHour || (currentHour === endHour && currentMinute > time.getMinutes())) {
      currentHour = endHour;
      currentMinute = time.getMinutes();
  }

  timeArray.sort((a, b) => {
    const [h1, m1] = a.split(":").map(Number);
    const [h2, m2] = b.split(":").map(Number);
    return h1 * 60 + m1 - (h2 * 60 + m2);
});

    // Формируем строку времени и добавляем в массив
    timeArray.push(`${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`);
}

// Выбираем случайное время из 10 сгенерированных
console.log(timeArray)

const selectedTime = timeArray[index];

// Возвращаем значение
return selectedTime;
  }
  
 
  
   function getDate(){
  const date=new Date()
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth()+1).padStart(2, '0')
  const year = date.getFullYear()
  
  return `${day}.${month}.${year}`
 }

  


  useEffect(()=>{

 
    
  
},[])






  return (
    <>
     
     <div className="gala-newsticker-container">

      <p className='gala-newsticker-header_container'><p>newsticker</p><div className='gala-newsticker-img_container'><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#B2804B" d="M20.67 22.67q.42-2.265 1.68-4.05c.84-1.2 1.8-2.2 2.89-2.96H0v-1.99h25.24a12 12 0 0 1-2.89-2.93c-.84-1.2-1.4-2.56-1.68-4.07h2.57c.27 1 .59 1.95.97 2.83.4.87.89 1.62 1.46 2.27a6.8 6.8 0 0 0 2.08 1.54c.8.38 1.75.57 2.83.57h.09v1.57h-.09a6.5 6.5 0 0 0-2.83.58c-.8.38-1.5.9-2.08 1.53a9 9 0 0 0-1.46 2.3c-.38.88-.7 1.82-.97 2.8h-2.57z"/></svg></div></p>

    <div className="gala-newsticker-container-news">
      <News/>
      </div>
            
      </div>
   </>
 
  );



  function News(){
    return(

      Data.channel.item.slice(0,10).map((item, index)=>{
        
        return(
          <div className='gala-newsticker'>
          <div className='gala-newsticker_time'>{getDate()}{" - "}{getime(index)}{" UHR "}</div>
        <p>{item.title}</p>
        </div>
        )
      })
    )
  }



  }






export default NewsTicker;
