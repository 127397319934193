import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import LogoFb from '../assets/logos/icon__fb.svg'
import LogoX from '../assets/logos/icon__twitter.svg'
import LogoInst from '../assets/logos/icon__instagram.svg'
import LogoMast from '../assets/logos/icon__mastodon.svg'
import LogoRss from '../assets/logos/icon__rss.svg'

import './styles.scss'


function Footer() {


  



  useEffect(()=>{


    
  
},[])






  return (
   <div className='ntv-footer'>

     <div className='ntv-footer-container'>

      <div className='ntv-footer-items'>
      <p className='ntv-footer-items-head'>ntv Nachrichtenfernsehen</p> 
      <p>Programm</p> 
      <p>Mitschnitte</p> 
      <p>Zuschauerredaktion</p> 
      <p>Pressestelle</p> 
      <p>Kontakt</p> 
      <p>Hinweisgeber</p> 
      <p>Jobs bei ntv.de</p> 
      <p>Digital Signage</p>
      </div>

      <div className='ntv-footer-items'>
      <p className='ntv-footer-items-head'>ntv.de Dienste</p> 
      <p>mobil & Apps</p> 
      <p>Newsletter</p> 
      <p>RSS-Feeds</p>
      </div>

      <div className='ntv-footer-items'>
      <p className='ntv-footer-items-head'>Social Networks</p> 
      <p>Facebook</p> 
      <p>X</p>
    
      <p className='ntv-footer-items-head'>Kooperationspartner</p> 
      <p>Gutscheine</p> 
      <p>Produktvergleiche</p> 
      <p>Autoleasing</p> 
      <p>Unternehmen</p> 
      <p>Immobilienbewertung</p> 
      <p>Broker-Vergleich</p> 
      <p>Website erstellen</p> 
      <p>Hosting</p>
      </div>

      <div className='ntv-footer-items ntvTextField'>
      <p className='ntv-footer-items-head'>Newsletter</p> 
      <p className='ntv-footer-items-text'>Ich möchte gerne Nachrichten und redaktionelle Artikel von der n-tv Nachrichtenfernsehen GmbH per E-Mail</p> 
      <div className='ntv-footer-items-text_field-container'>
        <div className='ntv-footer-items-text_field'></div>
        <p className='ntv-footer-items-text_field-btn'>Abonnieren</p>
      </div>
      </div>

    </div>


    <div className='ntv-footer2-container'>
      <p className='ntv-footer2-menu'>Impressum | Datenschutzerklärung | Privacy Center | Nutzungsbedingungen</p>

      <div className='ntv-footer2-icons'>
        <div className='ntv-footer2-icon'><img src={LogoFb}/></div>
        <div className='ntv-footer2-icon'><img src={LogoX}/></div>
        <div className='ntv-footer2-icon'><img src={LogoInst}/></div>
        <div className='ntv-footer2-icon'><img src={LogoMast}/></div>
        <div className='ntv-footer2-icon'><img src={LogoRss}/></div>
      </div>

      <p className='ntv-footer2-foot_text'>Alle Rechte vorbehalten</p>


    </div>

   </div>
 
  );


  }




export default Footer;
