import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams} from "react-router-dom";
import NoImg from '../../../../Images/noimg.jpg'
import Dummy4 from '../assets/img/dummy4.jpg'
import Data from '../../../../data/inpage/ntv/data-video.json'
import DataTest from '../../../../data/inpage/ntv/test.json'
import './styles.scss'
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

function ArtikelTeaserItems({img, category, title, text, rate}) {


  



  useEffect(()=>{


    
  
},[])






  return (
    <>
     
    
     
     <div className='chef-article_teaser-items'>
 
      <div className='chef-article_teaser-items-img-container'>
      <img className='chef-article_teaser-items-img' src={img} onError={(e)=>{e.target.onerror = null; e.target.src=NoImg}}/>
      
   
      </div>

      <div className='chef-article_teaser-items-text-container'>
      <Box className="chef-rating_container" sx={{ '& > legend': { mt: 2 } }}>
        <Rating className='chef-rating-star' size="small" name="read-only" value={1} max={1} readOnly/>
        <div className='chef-rating-value'>{rate}</div>
        </Box>

      <p className='chef-article_teaser-items-desk-text'> {title}</p>
    

     
      <div className='chef-article_teaser-items-text_author'></div>
      </div>

      
  </div>









    

   </>
 
  );


  }






export default ArtikelTeaserItems;
