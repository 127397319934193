import React,{useEffect, useState, Component, useRef} from 'react';
import Wolke from '../assets/logos/wolke.png'
import axios from 'axios';
import './styles.scss'


function Panel() {


  
 const [maxtemp, setMaxTemp]=useState(0)
 const [mintemp, setMinTemp]=useState(0)
 const [day, setDay]=useState([])
 const [icon, setIcon]=useState(null)

 const [weather, setWeather]=useState({})


  useEffect(()=>{

 
    CalcPercent()
    getWeather()
 
  
},[])


  function CalcPercent(){
    document.querySelectorAll('.ntv-panel-stock-item_value').forEach((item)=>{
      const value=item.querySelector('span')
     
      if(parseFloat(value.textContent)<0){
        value.style.color="red"
      }
      else{
         value.style.color="green"
      }
      
    })
  }

  async function getWeather(){

    const apiKey = "906324572048adae8f14985532d1560b"; 
const city = "Berlin"; 
const country = "DE"; 

const url = `https://api.openweathermap.org/data/2.5/forecast?q=${city},${country}&units=metric&lang=de&appid=${apiKey}`;


    const data= await axios.get(url)
    const foreCast={}
  
   

    //console.log(data)

    data.data.list.forEach((item,index)=>{
      const date=new Date(item.dt*1000).toLocaleDateString("de-DE")
      const weekDay=new Date(item.dt*1000).toLocaleDateString("de-DE", {weekday: "short"})

      if(!foreCast[date]){
      foreCast[date]={
        max_temp: Math.floor(item.main.temp_max),
        min_temp: Math.floor(item.main.temp_min),
        description:item.weather[0].description,
        icon: item.weather[0].icon,
        day:weekDay
      }
      }

     

    })
      
    const datas=Object.keys(foreCast).slice(0, 4)
   
  setWeather(foreCast)
    
   

 
    datas.forEach(date => {


    setIcon(foreCast[date].icon)
    setMaxTemp(foreCast[date].max_temp)
 


    });
    

  }



  function SetupWeather({forecast}){
  

    const forecastArray = Object.entries(forecast).slice(1, 4).map(([date, data]) => ({
      date,
      ...data
    }));

    console.log(forecastArray)

    return forecastArray.map((item,index)=>{

      return(
        <div className='ntv-panel-wetter-item'>
<div className='ntv-panel-wetter-item_day'>{item.day}</div>
<div className='ntv-panel-wetter-item_temp1'><span>{item.max_temp}</span>/{item.min_temp}°</div>
</div>
      )
      
    
    })

   

  }


  return (
   <div className='ntv-panel'>

    <div className='ntv-panel-container'>

<div className='ntv-panel-stock-container'>
<h3>börsenkurse</h3>
<div className='ntv-panel-stock-items_container'>

<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>DAX</div>
<div className='ntv-panel-stock-item_value'>19.251 <span>-0.3%</span></div>
<div className='ntv-panel-stock-item_name'>MDAX</div>
<div className='ntv-panel-stock-item_value'>256.548 <span>0.2%</span></div>
<div className='ntv-panel-stock-item_name'>ESX</div>
<div className='ntv-panel-stock-item_value'>4.816 <span>-0.6%</span></div>
</div>
<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>DAX Ind</div>
<div className='ntv-panel-stock-item_value'>19.244 <span>0.0%</span></div>
<div className='ntv-panel-stock-item_name'>DOW Ind</div>
<div className='ntv-panel-stock-item_value'>43.613 <span>-0.4%</span></div>
<div className='ntv-panel-stock-item_name'>ESX Ind</div>
<div className='ntv-panel-stock-item_value'>4.814 <span>-0.1%</span></div>
</div>
<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>EUR</div>
<div className='ntv-panel-stock-item_value'>1,0569 <span>0.3%</span></div>
<div className='ntv-panel-stock-item_name'>Öl</div>
<div className='ntv-panel-stock-item_value'>68,34 <span>-0.2%</span></div>
<div className='ntv-panel-stock-item_name'>Gold</div>
<div className='ntv-panel-stock-item_value'>2.568 <span>0.0%</span></div>
</div>
<div className='ntv-panel-stock-item_container'>
<div className='ntv-panel-stock-item_name'>Bitcion</div>
<div className='ntv-panel-stock-item_value'>89.452 <span>2.0%</span></div>
<div className='ntv-panel-stock-item_name'>Silber</div>
<div className='ntv-panel-stock-item_value'>30,64 <span>0.5%</span></div>
<div className='ntv-panel-stock-item_name'>Platinum</div>
<div className='ntv-panel-stock-item_value'>897 <span>0.6%</span></div>
</div>

<div className='ntv-panel-stock-item_container_mobile'>
<div className='ntv-panel-stock-item_name'>DAX</div>
<div className='ntv-panel-stock-item_value'>19.251 <span>-0.3%</span></div>
<div className='ntv-panel-stock-item_name'>MDAX</div>
<div className='ntv-panel-stock-item_value'>256.548 <span>0.2%</span></div>
</div>
<div className='ntv-panel-stock-item_container_mobile'>
<div className='ntv-panel-stock-item_name'>DAX Ind</div>
<div className='ntv-panel-stock-item_value'>19.244 <span>0.0%</span></div>
<div className='ntv-panel-stock-item_name'>DOW Ind</div>
<div className='ntv-panel-stock-item_value'>43.613 <span>-0.4%</span></div>
</div>

</div>



</div>

<div className='ntv-panel-wetter-container'>

<h3>WETTER</h3>
<div className='ntv-panel-wetter-items_container'>

<div className='ntv-panel-wetter-item_container ntvWetterImg-container'>
<div className='ntv-panel-wetter-img_container'><img src={`https://openweathermap.org/img/wn/${icon}@4x.png`}/></div>
<div className='ntv-panel-wetter-item-temp'>{maxtemp}</div>
</div>
<div className='ntv-panel-wetter-item_container'>

<SetupWeather forecast={weather}/>

</div>

</div>

</div>
    </div>
  
   </div>
 
  );


  }




export default Panel;
