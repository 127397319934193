import React,{useEffect, useState, Component, useRef} from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useParams, useSearchParams} from "react-router-dom";
import Footer from '../Footer/Footer';
import Data from '../../../../data/inpage/chef/data.json'
import Ads from '../../../../data/inpage/ntv/ads.json'
import GalaMr from '../Ads/GalaMr';
import ArtikelHighLight from './ArtikelHighLight';
import ArtikelHighLight2 from './ArtikelHighLight2';
import ArtikelHighLight3 from './ArtikelHighLight3';
import ArtikelHighLight3Mob from './ArtikelHighLight3Mob';
import ArtikelTeaser from './ArtikelTeaser';
import ArtikelTeaserBlock from './ArtikelTeaserBlock';
import ArtikelTeaserItems from './ArtikelTeaserItems';
import ArtikelTeaserItems2 from './ArtikelTeaserItems2';
import ArtikelTeaserItemsPlus from './ArtikelTeaserItemsPlus';

import HighLightData from '../../../../data/inpage/chef/highlight.json'
import ItemsBlockData from '../../../../data/inpage/chef/items.json'
import Dummy from '../assets/img/mainDummy.jpg'

import './styles.scss'



function Artikel({teaser, adContent}) {


   
  
    const mrAd=useRef(null)


  useEffect(()=>{

console.log(Data)
  
},[])


/*

 { range: [0, 1], layoutType: "highlight" },
        { range: [1, 4], layoutType: "teaser" },
        { range: [4, 5], layoutType: "medrec" },
        { range: [5, 11], layoutType: "teaserblock" },
        { range: [11, 12], layoutType: "highlight" },
        { range: [12, 15], layoutType: "teaseritem" },
        { range: [15, 16], range2: [16, 20], layoutType: "teaserblock-teaser" },
        { range: [20, 21], range2: [21, 23], layoutType: "teaserblock-teaser" },
        { range: [23, 24], layoutType: "highlight" },
        { range: [24, 26], range2: [26, 30], layoutType: "teaserblock-teaser" },
        { range: [30, 33], range2: [33, 35], layoutType: "teaserblock-teaser" },
        { range: [35, 36], layoutType: "highlight" },
        { range: [36, 39], layoutType: "teaseritem" },
        { range: [39, 40], layoutType: "highlight" },
        { range: [40, 41], range2: [41, 45], layoutType: "teaserblock-teaser" },
        { range: [45, 51], layoutType: "teaseritem" },
        { range: [51, 55], layoutType: "teaser" },
        { range: [55, 57], range2: [57, 59], layoutType: "teaserblock-teaser" },
        { range: [59, 60], layoutType: "highlight" },
        { range: [60, 64], layoutType: "teaser" },
        { range: [64, 66], range2: [66, 70], layoutType: "teaserblock-teaser" },
        { range: [70, 74], layoutType: "teaser" },
        { range: [74, 80], layoutType: "teaseritem" },


*/





  return (
    <>
 
 <div className='chef-main_article-container'>
   
       <SetArtikel adContent={adContent}/>
       
       <Footer/>



   </div>
   {/*teaser ? <SetMain adContent={adContent}/> : <SetMain2/>*/}
   


       
   </>
 
  );


  }


  function SetArtikel({adContent}) {

    const layoutsConfig = [
        { range: [0, 1], layoutType: "highlight" },
        { range: [0, 3], layoutType: "highlight2" },
        { range: [0, 1], layoutType: "medrec" },
        { range: [3, 6], headtext:"Klassiker & frische Ideen", layoutType: "teaseritem" },
        { range: [31, 34], headtext:"Schon probiert", layoutType: "teaseritem2" },
        { range: [13, 16], layoutType: "highlight3" },
        { range: [25, 28], headtext:"Dein Chefkoch PLUS", layoutType: "teaseritemplus" },
        { range: [28, 31], headtext:"Must-Haves für deine Küche", layoutType: "teaseritemplus" },
        { range: [22, 25], headtext:"Neue Rezepte", layoutType:  "teaseritem2" },
        { range: [0, 1], headtext:"Trends & Saisonales", layoutType: "teaserblock" },
        { range: [38, 41], headtext:"Kochkurse von Chefkoch", layoutType: "teaseritemplus" },
        { range: [16, 19], headtext:"Inspiration für jeden Tag", layoutType: "teaseritem" },
        { range: [19, 22], headtext:"Noch nicht satt", layoutType: "teaseritem" },
        { range: [34, 37], headtext:"Leсkere Rezepte", layoutType: "teaseritem" },
        { range: [49, 52],  layoutType: "teaseritem" },
        { range: [52, 55],  layoutType: "teaseritem" },
        { range: [55, 58],  layoutType: "teaseritem" },
        { range: [58, 61],  layoutType: "teaseritem" },
        { range: [61, 64],  layoutType: "teaseritem" },
        { range: [64, 67],  layoutType: "teaseritem" },
        { range: [67, 70],  layoutType: "teaseritem" },
        { range: [70, 73],  layoutType: "teaseritem" },
        { range: [73, 76],  layoutType: "teaseritem" },
        { range: [76, 79],  layoutType: "teaseritem" },
        { range: [41, 44], headtext:"Unsere Empfehlungen", layoutType: "teaseritemplus" },
        { range: [79, 82], layoutType: "teaseritemplus" },
        { range: [82, 85], layoutType: "teaseritemplus" },
       
      
    
      ];
    
  const articlesWithLayout = layoutsConfig.map((config) => {
      const items=Data.channel.item.slice(...config.range)
      const itemsHeadText = config.headtext
      
      return{items, ...(itemsHeadText && { itemsHeadText }),
      layoutType: config.layoutType,}
    });
   


    return (
      <>
        {articlesWithLayout.map((item, index) => {
            
          switch (item.layoutType) {
            case "highlight":
              return (
                <div className='chef-hightlight_article'>
         <swiper-container id="chef-hightlight-swiper" prevent-interaction-on-transition="true" slides-per-view="1" navigation="true" pagination="true" pagination-clickable="true" loop="true" css-mode="true">

                     {HighLightData.items.map((item, index) => {
                     
                     
                        return(
          
                <ArtikelHighLight
                  key={index}
                  img={item.url}
                  title={item.title}
                  text={item.text}
                  action={item.textFoot}
    
                 
                />
          
            )
          
       
        })}

        </swiper-container>
                </div>
              );
              
             
              case "highlight2":
             
              return (
                <div className="chef-highlight2-container" key={index}>

                  <p className='chef-highlight2-container_title'>Rezepte des Tages <span>Alle Rezepte dieser Woche →</span></p>

                  <div className="chef-highlight2" key={index}>

                    {item.items.map((item, index) => {
                    
                    return(
                  <ArtikelHighLight2
                    img={item.enclosure["@url"]}
                    title={item.title}
                    rate={item.rate}
                  />
                )
                })}
                
              
                 </div>
                 </div>
                
               
              );
              case "highlight3":
             
              return (
                <div className="chef-highlight2-container" key={index}>

                  <p className='chef-highlight2-container_title'>Unser Rezept-Roulette</p>

                  <div className="chef-highlight3" key={index}>


                  <ArtikelHighLight3/>
                
        
                 </div>
                  <div className="chef-highlight3-mobile" key={index}>


                  <ArtikelHighLight3Mob/>
                
        
                 </div>
                 </div>
                
               
              );
             
              case "teaser":
             
              return (
                <div className="gala-article-container" key={index}>



                    {item.items.map((item, index) => {
                    
                    return(
                  <ArtikelTeaser
                    img={item.enclosure["@url"]}
                    category={item.category ? item.category[0] : ""}
                    title={item.title}
                    text={item.description}
                   // author={item.creator ? item.creator : ""}
                  />
                )
                })}
                
              
                 </div>
                
               
              );


              case "medrec":
             
              return  adContent!=null ?(
                
                <div className="chef-article-medrec" key={index}>

                  <GalaMr adContent={adContent}/>

                 </div>
               
              ):null
                
        
           
            
            case "teaserblock":
              return (
                <div className='chef-artikel_teaser-block_container' key={index}>
                  <p className='chef-artikel_teaser-items-title'>{item.itemsHeadText}</p>
                     <div className='chef-artikel_teaser-block-body'>

                     <swiper-container id="chef-teaserblock-swiper" slides-per-view="4" slides-per-group="8" navigation="true" pagination="true" pagination-clickable="true" loop="true" css-mode="true">
                     {ItemsBlockData.items.map((item, index) => {
                    
                    return(
                <ArtikelTeaserBlock
                  key={index}
                  img={item.url}
                  title={item.title}
                  rate={item.rate}
                
                />
            )
        })}

        </swiper-container>
                </div>
                </div>
              );
   
            case "teaseritem":
            
              return (
                
                
                <div className='chef-artikel_teaser-items_container'>

              <p className='chef-artikel_teaser-items-title'>{item.itemsHeadText}</p>
                <div className='chef-artikel_teaser-items-body'>
                {item.items.map((item, index) => {
                    
                    return(
                <ArtikelTeaserItems
                  key={index}
                  img={item.enclosure["@url"]}
                  category={item.category ? item.category[0] : ""}
                  title={item.title}
                  text={item.description}
                  rate={item.rate}
                 
                />
            )
        })}

</div>
</div>
           
              );

            case "teaseritemplus":
            
              return (
                
                
                <div className='chef-artikel_teaser-items_container'>

              <p className='chef-artikel_teaser-items-title'>{item.itemsHeadText}</p>
                <div className='chef-artikel_teaser-items-body'>
                {item.items.map((item, index) => {
                    
                    return(
                <ArtikelTeaserItemsPlus
                  key={index}
                  img={item.enclosure["@url"]}
                  category={item.category ? item.category[0] : ""}
                  title={item.title}
                  text={item.description}
              
                 
                />
            )
        })}

</div>
</div>
           
              );
   
            case "teaseritem2":
              return (
                <div className='chef-artikel_teaser-items_container'>
          <p className='chef-artikel_teaser-items-title'>{item.itemsHeadText}</p>
                <div className='chef-artikel_teaser-items2-body'>
                {item.items.map((item, index) => {
                    
                    return(
                <ArtikelTeaserItems2
                  key={index}
                  img={item.enclosure["@url"]}
                  category={item.category ? item.category[0] : ""}
                  title={item.title}
                  text={item.description}
                  rate={item.rate}
                 
                />
            )
        })}

</div>
</div>
           
              );
   
            case "teaserblock-teaser":
              return (
                
                <div key={index}>
                    <div className="stern-article-container" key={index}>

                        {item.items.map((item, index) => {
                           
                        return(
                      <ArtikelTeaser
                    img={item.enclosure["@url"]}
                    category={item.category ? item.category[0] : ""}
                    title={item.title}
                    text={item.description}
                    //author={item.creator ? item.creator : ""}
                  />
                        )
                    })}
                </div>

                <div className='stern-artikel_teaser-block_container' key={index}>
                     <div className='stern-artikel_teaser-block-body'>
                     {item.itemsTeaserBlock.map((item, index) => {
                        return(
                  <ArtikelTeaserBlock
                    img={item.enclosure["@url"]}
                    category={item.category ? item.category[0] : ""}
                    title={item.title}
                    text={item.description}
                    //author={item.creator ? item.creator : ""}
                  />
                )
            })}
        </div>
        </div>
                </div>
              );
   
            default:
              return null;
          }
        })}
      </>
    );
  }


/* 
  function SetArtikel(){
    return Data.channel.item.map((item,index)=>{
        //const [beforeColon, afterColon] = item.title.split(':');
    console.log(index)

        return(
            
       <ArtikelHighLight img={item.enclosure['@url']} category={item.category ? item.category[0] : ""} title={item.title} text={item.description} author={item.creator ? item.creator : ""}/>
        
        )
    })

   }
  
*/
   
   function transformLink(originalLink) {
    return originalLink
        .replace('/img/incoming/crop', '/img/') 
        .replace('-cImg_4_3-w250', '/17-6/1136') 
        .replace(/\/img\/(\d+)\/(\d+)/, '/img/$1-$2/');
}


/* 
  function SetMain({adContent}){
    const [searchParams] = useSearchParams();
    const mr = searchParams.get('mr');
    const paramName = Array.from(searchParams.keys())[0];
   
    return Data.rss.channel.item.map((item,index)=>{
        const [beforeColon, afterColon] = item.title.split(':');
        const transformedLink=transformLink(item.enclosure._url)
        if(index>4 && index<10){

            if(index==5 && adContent!=null && paramName=="mr"){
    
                return(

                   <NtvMr adContent={adContent} key={index}/>
                )
            }
            else{

            return(
                <div className='ntv-article-day-container-main' key={index}>
                <div className='ntv-article-day-img-container-main'>
                <img className='ntv-article-day-img' src={item.enclosure._url}/>
                <img className='ntv-article-day-img_mobile-main' src={item.enclosure._url}/>
             
                </div>
        
                <div className='ntv-article-day-text-container-main'>
                <div className='ntv-article-main-theme'> {item.category}</div>
                <div className='ntv-article-main-desk-head'>
                {beforeColon}
                </div>

                <div className='ntv-article-main-desk-text'>{afterColon}</div>

                <div className='ntv-article-main-desk-desc-main'>{item.description}</div>     
                </div>
        
            </div>
            )
        }
        }

       
        
        else{
        return(
            <div className='ntv-article-main_container' key={index+10}>

            <div className='ntv-article-main_img-container'>
            <img src={transformedLink}/>
            </div>

            <div className='ntv-article-main-theme'>
             {item.category}
            </div>

            <div className='ntv-article-main-desk-head'>
                {beforeColon}
            </div>

         <div className='ntv-article-main-desk-text'>{afterColon}</div>
                   
         <div className='ntv-article-main-desk-desc'>{item.description}</div>        
           
 

            </div>
        )
    }
    })
    

   }
    */

   function transformLinkTeaser(originalLinkTeaser) {
    return originalLinkTeaser
    .replace('/img/incoming/crop', '/img/') 
    .replace('-cImg_4_3-w250', '/17-6/320') 
    .replace(/\/img\/(\d+)\/(\d+)/, '/img/$1-$2/');
}


 /* 
function SetArtikel() {
const groupedItems = Data.channel.item.reduce((acc, item, index) => {
    if (index === 0) acc.big.push(item);
    else if (index > 0 && index <= 3) acc.small.push(item);
    else acc.default.push(item);
    return acc;
  }, { big: [], small: [], default: [] });
 
  return (
    <>
      {groupedItems.big.map((item, index) => (
        <ArtikelHighLight
          key={index}
          className="big-article"
          img={item.enclosure['@url']}
          category={item.category ? item.category[0] : ""}
          title={item.title}
          text={item.description}
          author={item.creator || ""}
        />
      ))}
      {groupedItems.small.map((item, index) => (
        <ArtikelHighLight
          key={index}
          className="small-article"
          img={item.enclosure['@url']}
          category={item.category ? item.category[0] : ""}
          title={item.title}
          text={item.description}
          author={item.creator || ""}
        />
      ))}
      {groupedItems.default.map((item, index) => (
        <ArtikelHighLight
          key={index}
          className="default-article"
          img={item.enclosure['@url']}
          category={item.category ? item.category[0] : ""}
          title={item.title}
          text={item.description}
          author={item.creator || ""}
        />
      ))}
    </>
  );
}
*/


export default Artikel;
